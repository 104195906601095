<template>
  <a-modal title="详情" :width="800" :visible="visible" :destroyOnClose="true" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <div class="detail-wrapper">
        <a-form labelAlign="left" layout="inline" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
          <!-- 第1行详情 -->
          <div class="form-item">
            <a-form-item label="运费模板名称">
              <div>{{ detail.name }}</div>
            </a-form-item>
          </div>
          <!-- 第3行详情 -->
          <div class="form-item">
            <a-form-item label="备注">
              <div>{{ detail.comment }}</div>
            </a-form-item>
          </div>
          <div class="form-item">
            <a-form-item label="是否默认">
              <div>
                <a-switch
                  :disabled="true"
                  checked-children="是"
                  un-checked-children="否"
                  :checked="+detail.isDefault === 2"
                />
              </div>
            </a-form-item>
          </div>
        </a-form>
        <div class="table-container">
          <h3>运费规则</h3>
          <a-table :pagination="false" :columns="columns" :data-source="freightRules" tableLayout="auto" bordered>
          </a-table>
        </div>
      </div>
    </a-spin>
    <template slot="footer">
      <div style="display: flex;justify-content: center;">
        <a-button key="submit" type="primary" @click="handleCancel">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mallFreightDetail } from '@/api/modular/mallLiving/orderAdmin'

const columns = [
  {
    title: '可配送区域',
    dataIndex: 'provinceNames'
  },

  {
    title: '邮费（元）',
    dataIndex: 'price'
  }
]

export default {
  data() {
    return {
      columns,
      freightRules: [],
      visible: false, //modal框显示状态
      confirmLoading: false,
      detail: {}
    }
  },
  methods: {
    //初始化方法
    getDetail(record) {
      this.visible = true
      this.confirmLoading = true

      mallFreightDetail({ id: record.id })
        .then(res => {
          console.log('詳情', res)
          if (res.success) {
            const data = res.data
            this.detail = data
            this.freightRules = data.freightRulesList
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.confirmLoading = false
          }, 200)
        })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      setTimeout(() => {
        this.detail = {} //关闭之后清空
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.detail-wrapper {
  border: 1px solid #ebeef5;
  .form-item {
    padding: 0 10px;
    border-bottom: 1px solid #ebeef5;
  }
  .ant-form-item {
    width: 100%;
  }
  /deep/.ant-form-item-control-wrapper {
    padding: 0 10px;

    border-left: 1px solid #ebeef5;
  }
  .table-container {
    padding: 15px 10px 10px;
    h3 {
      text-align: center;
      font-weight: 700;
      font-size: 17px;
      color: #606266;
    }
  }
}
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding: 7px;
    font-size: 15px;
    width: 30%;
  }
}

img {
  width: 100px;
}
</style>
