//订单管理相关接口
import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 * @Date: 2022-02-8 11:15:50
 */

//新增运费模板
export function addMallFreight(parameter) {
  return axios({
    url: '/mallFreight/add',
    method: 'post',
    data: parameter,
  })
}
//编辑运费模板
export function editMallFreight(parameter) {
  return axios({
    url: '/mallFreight/edit',
    method: 'post',
    data: parameter,
  })
}
//删除运费模板
export function removeMallFreight(parameter) {
  return axios({
    url: '/mallFreight/delete',
    method: 'post',
    data: parameter,
  })
}
//切换运费模板默认状态
export function editIsDefault(parameter) {
  return axios({
    url: '/mallFreight/editIsDefult',
    method: 'post',
    data: parameter,
  })
}
//运费模板详情
export function mallFreightDetail(parameter) {
  return axios({
    url: '/mallFreight/detail',
    method: 'post',
    data: parameter,
  })
}

//运费模板列表
export function mallFreightPage(parameter) {
  return axios({
    url: '/mallFreight/page',
    method: 'post',
    data: parameter,
  })
}
//查询全部省份
export function allProvince() {
  return axios({
    url: '/sysDistrict/allProvince',
    method: 'post',
  })
}

//订单列表分页查询
export function getOrderList(parameter) {
  return axios({
    url: '/order/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//订单列表发送货
export function orderSend(parameter) {
  return axios({
    url: '/order/send',
    method: 'post',
    data: parameter,
  })
}
//订单列表退货
export function orderReturn(parameter) {
  return axios({
    url: '/order/refund',
    method: 'post',
    data: parameter,
  })
}
//订单详情
export function orderDetail(parameter) {
  return axios({
    url: '/order/detail',
    method: 'post',
    params: parameter,
  })
}
//物流信息流程
export function wuliuInfo(parameter) {
  return axios({
    url: '/order/logisticsInformation',
    method: 'post',
    data: parameter,
  })
}
//订单列表顶部数据
export function orderTop(parameter) {
  return axios({
    url: '/order/dataStatistics',
    method: 'post',
    data: parameter,
  })
}

//收银订单分页查询
export function cashOrderList(parameter) {
  return axios({
    url: '/order/cashOrderPage',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

export function exportOrderData(parameter) {
  return axios({
    url: '/order/orderExportByParam',
    method: 'post',
    data: parameter,
  })
}

export function downloadExcel(data = {}) {
  return axios({
    method: 'post',
    url: '/order/downloadExcel',
    data,
    responseType: 'blob',
  })
}

// 导入数据
export function batchDelivery(data = {}) {
  return axios({
    method: 'post',
    url: '/order/ajaxUpload',
    data,
    headers: {
      'Content-type': 'multipart/form-data',
    },
    responseType: 'blob',
  })
}

//分页查询订单商品对账数据
export function orderGoodPage(parameter) {
  return axios({
    url: '/order/goodsPage',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
